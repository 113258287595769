import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { client as apollo } from 'cccisd-apollo';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import Loader from 'cccisd-loader';

import style from './style.css';
import commonStyles from '../StudentCommon/style.css';
import teacherQuery from './teacherQuery.graphql';

const Fortress = window.cccisd.fortress;

class TeacherAssignment extends React.Component {
    static propTypes = {
        onComplete: PropTypes.func,
        history: PropTypes.any,
    };

    static defaultProps = {
        onComplete: null,
    };

    state = {
        loading: true,
        error: false,
        teacher: null,
        session: null,
    };

    componentDidMount() {
        document.body.classList.add(commonStyles.blueGradient);
        this.getTeacherInfo();
    }

    componentWillUnmount() {
        document.body.classList.remove(commonStyles.blueGradient);
    }

    getTeacherInfo = async () => {
        const result = await apollo.query({
            query: teacherQuery,
            fetchPolicy: 'network-only',
        });

        const teacher = result.data.roles.instructor;
        if (!teacher) {
            this.setState({ loading: false, error: true });
            return;
        }

        // If no plan selected at School level, check for District defaults
        let session;
        if (teacher?.parentGroup?.site?.selectedAssignmentPlan?.assignmentPlanId) {
            const currentSession = teacher?.parentGroup?.site?.selectedAssignmentPlan?.currentSession || '';
            session = currentSession?.sessionId ? currentSession : '';
        } else {
            const defaultPlanId = (teacher?.ancestorGroups?.groupingUnit?.group?.settings?.defaultAssignmentPlans || {})
                .plan_teacher;

            const availablePlans = teacher?.ancestorGroups?.organization?.createdAssignmentPlanList || [];
            const planDef =
                defaultPlanId &&
                availablePlans.find(
                    plan => plan?.role === 'instructor' && plan?.assignmentPlanId === parseInt(defaultPlanId, 10)
                );

            session = planDef?.currentSession?.sessionId ? planDef.currentSession : '';
        }
        this.setState({ loading: false, teacher, session });
    };

    onComplete = () => {
        this.props.history.push('/teacher');
        if (this.props.onComplete) {
            this.props.onComplete();
        }
    };

    showError = () => (
        <div className={style.wrapper}>
            Error retrieving teacher information
            <br />
            <br />
            Please contact support
        </div>
    );

    showNoSession = () => (
        <div className={style.wrapper}>
            No teacher data collection is currently active for this school.
            <br />
            <br />
            <Link to={Fortress.settings.config.after_login_url}>
                <button type="button" className="btn btn-primary">
                    Return Home
                </button>
            </Link>
        </div>
    );

    showAssignment = () => {
        var { teacher, session } = this.state;
        var mediator = {
            mustTakeInOrder: false,
        };
        return (
            <DeploymentPlayer
                deploymentId={session.deployment.deploymentId}
                pawnId={teacher.pawn.pawnId}
                pawnHash={teacher.pawn.pawnHash}
                assignmentOptions={session.settings.options}
                mediatorProps={mediator}
                disableLayout
                onComplete={this.onComplete}
            />
        );
    };

    render() {
        const { loading, error, session } = this.state;

        if (loading) {
            return <Loader loading />;
        }

        if (error) {
            return this.showError();
        }

        if (!session) {
            return this.showNoSession();
        }

        return <div className={style.wrapper}>{this.showAssignment()}</div>;
    }
}
export default withRouter(TeacherAssignment);
